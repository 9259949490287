import axios from "../_helpers/axios";

export const dashboardService = {
	get_data,
};

async function get_data(account_id = null) {
	return new Promise((resolve, reject) => {
		const params = { account_id };
		axios
			.get("dashboard", { params })
			.then((resp) => {
				if (resp.data && resp.data.data) {
					resolve(resp.data.data);
				}
			})
			.catch(function(error) {
				if (
					error.response &&
					error.response.data &&
					error.response.data.error
				) {
					reject(error.response.data.error.error_message);
					return;
				}
				reject(error);
			});
	});
}
